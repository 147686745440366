import axios from "axios";
import { conf } from "../utils/config";
import { Novedad } from "../types/novedad";

const backNotificationAxios = axios.create({
  baseURL: conf("BACKEND_NOTIFICATION_URL"),
});

const backProxyAxios = axios.create({
  baseURL: conf("BACKEND_PROXY_URL"),
});

const backSyncAxios = axios.create({
  baseURL: conf("BACKEND_SYNC_URL"),
});

export const dashboardAPI = {
  syncronizarAlumnos: async (
    identificacion: number | null,
    programa: number | null,
    orientacion: number | null
  ) => {
    const response: any = await axios.post(`/dashboard/syncronizarAlumnos`, {
      identificacion: identificacion,
      programa: programa,
      orientacion: orientacion,
    });
    return response.data;
  },
  postVencerOfertas: async (
    identificacion: number | null,
    programa: number | null,
    orientacion: number | null
  ) => {
    const response: any = await axios.post(`/dashboard/vencerOfertasIPO`, {
      identificacion: identificacion,
      programa: programa,
      orientacion: orientacion,
    });
    return response.data;
  },
  postBorrarIPO: async (
    identificacion: number | null,
    programa: number | null,
    orientacion: number | null
  ) => {
    const response: any = await axios.post(`/dashboard/borrarIPO`, {
      identificacion: identificacion,
      programa: programa,
      orientacion: orientacion,
    });
    return response.data;
  },
  postResetearIPO: async (
    identificacion: number | null,
    programa: number | null,
    orientacion: number | null
  ) => {
    const response: any = await axios.post(`/dashboard/resetearIPO`, {
      identificacion: identificacion,
      programa: programa,
      orientacion: orientacion,
    });
    return response.data;
  },
  postInvalidarOfertas: async (
    identificacion: number | null,
    programa: number | null,
    orientacion: number | null
  ) => {
    const response: any = await axios.post(`/dashboard/invalidarOfertasIPO`, {
      identificacion: identificacion,
      programa: programa,
      orientacion: orientacion,
    });
    return response.data;
  },
  sincronizarSiguienteTabla: async () => {
    const response: any = await axios.post(
      `/dashboard/inicializarSiguienteTabla`,
      {}
    );
    return response.data;
  },
  inicializarConfiguracion: async () => {
    const response: any = await axios.post(`/dashboard/resetConfiguracion`, {});
    return response.data;
  },
  syncInfoTablas: async () => {
    const response: any = await axios.post(`/dashboard/syncInfoTablas`);
    return response.data;
  },
  borrarTodosLosAlumnos: async () => {
    const response: any = await axios.post(
      `/dashboard/borrarTodosLosAlumnos`,
      {}
    );
    return response.data;
  },
  alumnosInicializados: async () => {
    const response: any = await axios.post(
      `/dashboard/alumnosInicializados`,
      {}
    );
    return response.data;
  },
  inicializarAlumno: async (email: string) => {
    const response: any = await axios.post(`/dashboard/inicializarAlumno`, {
      email,
    });
    return response.data;
  },
  obtenerDetalleAlumno: async (idAlumnoPrograma: number) => {
    const response: any = await axios.post(`/dashboard/detalleAlumno`, {
      idAlumnoPrograma,
    });
    return response.data;
  },
  inicializarTablasMirror: async () => {
    const response: any = await axios.post(
      `/dashboard/inicializarTablasMirror`,
      {}
    );
    return response.data;
  },
  inicializarTablaMirror: async (tabla: string) => {
    const response: any = await axios.post(
      `/dashboard/inicializarTablasMirror`,
      { tabla }
    );
    return response.data;
  },
  resetInfoAlumnos: async () => {
    const response: any = await axios.post(`/dashboard/resetInfoAlumnos`, {});
    return response.data;
  },
  deleteAllTables: async () => {
    const response: any = await axios.post(`/dashboard/deleteAllTables`, {});
    return response.data;
  },
  dropAllTables: async () => {
    const response: any = await axios.post(`/dashboard/dropAllTables`, {});
    return response.data;
  },
  actualizarCupos: async () => {
    const response = await axios.post(`/dashboard/actualizarTodosLosCupos`, {});
    return response.data;
  },
  actualizarCupo: async (idCupo: number) => {
    const response = await axios.post(`/dashboard/actualizarCupo`, { idCupo });
    return response.data;
  },
  syncCuposDescuadrados: async () => {
    const response = await axios.post(`/dashboard/syncCuposDescuadrados`, {});
    return response.data;
  },
  estadoTablasMirror: async () => {
    const response = await axios.post(`/dashboard/estadoTablasMirror`, {});
    return response.data;
  },
  checkSyncTablasMirror: async () => {
    const response = await axios.post(`/dashboard/checkSyncTablasMirror`, {});
    return response.data;
  },
    getDetalleCuadratura: async () => {
    const response = await axios.post(`/dashboard/getDetalleCuadratura`, {});
    return response.data;
  },
  procesarCuadratura: async () => {
    const response = await axios.post(`/dashboard/procesarCuadratura`, {});
    return response.data;
  },
  obtenerDetalleCupo: async (idCupo: number) => {
    const response = await axios.post(`/dashboard/detalleCupo`, {
      idCupo,
    });
    return response.data;
  },
  resetearAlumno: async (idPersona: number) => {
    const response = await axios.post(`/dashboard/resetearAlumno`, {
      idPersona,
    });
    return response.data;
  },
  eliminarAlumno: async (idPersona: number) => {
    const response = await axios.post(`/dashboard/eliminarAlumno`, {
      idPersona,
    });
    return response.data;
  },
  invalidarSemaforo: async (idPersona: number) => {
    const response = await axios.post(`/dashboard/invalidarSemaforo`, {
      idPersona,
    });
    return response.data;
  },
  resetearAlumnosRegistrados: async () => {
    const response = await axios.post(`/dashboard/resetearAlumnosRegistrados`);
    return response.data;
  },
  invalidarSemaforoGlobal: async () => {
    const response = await axios.post(`/dashboard/invalidarSemaforoGlobal`);
    return response.data;
  },
  vencerOferta: async (idAlumnoPrograma: number) => {
    const response = await axios.post(`/dashboard/vencerOferta`, {
      idAlumnoPrograma,
    });
    return response.data;
  },
  invalidarOferta: async (idAlumnoPrograma: number) => {
    const response = await axios.post(`/dashboard/invalidarOferta`, {
      idAlumnoPrograma,
    });
    return response.data;
  },
  invalidarOfertaGlobal: async () => {
    const response = await axios.post(`/dashboard/invalidarOfertaGlobal`);
    return response.data;
  },
  vencerOfertaGlobal: async () => {
    const response = await axios.post(`/dashboard/vencerOfertaGlobal`);
    return response.data;
  },
  actualizarOferta: async (idAlumnoPrograma: number) => {
    const response = await axios.post(`/dashboard/actualizarOferta`, {
      idAlumnoPrograma,
    });
    return response.data;
  },
  actualizarInscripciones: async (idAlumnoPrograma: number) => {
    const response = await axios.post(`/dashboard/actualizarInscripciones`, {
      idAlumnoPrograma,
    });
    return response.data;
  },
  obtenerAlumnosRegistrados: async () => {
    const response = await axios.post(`/dashboard/alumnosRegistrados`, {});
    return response.data;
  },
  obtenerAlumnosRegistradosPaginado: async (
    limit: number,
    offset: number,
    carrera?: string,
    searchText?: string,
    estadoAlumnoId?: string,
    conectado?: string
  ) => {
    const response = await axios.post(`/dashboard/alumnosRegistradosPag`, {
      limit,
      offset,
      carrera: carrera || "",
      searchText: searchText || "",
      estadoAlumnoId,
      conectado,
    });
    return response.data;
  },
  buscarNovedadesOracle: async () => {
    const response = await axios.get(`/dashboard/buscarNovedadesOracle`);
    return response.data;
  },
  obtenerReservasEnTransito: async () => {
    const response = await axios.get(`/dashboard/reservasEnTransito`);
    return response.data;
  },
  obtenerDesinscripcionEnTransito: async () => {
    const response = await axios.get(`/dashboard/desinscripcionesEnTransito`);
    return response.data;
  },
  obtenerParametros: async () => {
    const response = await axios.get(`/dashboard/parametros`);
    return response.data;
  },
  updateParametro: async (parametro: string, value: string) => {
    const response = await axios.put(`/dashboard/parametro`, {
      parametro,
      value,
    });
    return response.data;
  },
  obtenerValidaciones: async () => {
    const response = await axios.get(`/dashboard/validaciones`);
    return response.data;
  },
  inicializarValidaciones: async () => {
    const response = await axios.get(`/dashboard/inicializarValidaciones`);
    return response.data;
  },
  updateValidacion: async (
    activa: string | null,
    configuracion: string | null,
    id: string
  ) => {
    const response = await axios.put(`/dashboard/validacion`, {
      activa,
      configuracion,
      id,
    });
    return response.data;
  },
  obtenerDetallesDeColas: async () => {
    const response = await axios.get(`/dashboard/queues`);
    return response.data;
  },
  logoutGeneral: async () => {
    const response = await axios.get(`/dashboard/logoutGeneral`);
    return response.data;
  },
  logoutIndividual: async (idPersona: number) => {
    const response = await axios.post(`/dashboard/logoutIndividual`, {
      idPersona,
    });
    return response.data;
  },
  getVersionBackApi: async (clon: number | null) => {
    const response = await axios.get(`/version`);
    return response.data;
  },
  getVersionBackSync: async (clon: number | null) => {
    const response = await backSyncAxios.get(`/version`);
    return response.data;
  },
  getVersionBackNotification: async () => {
    const response = await backNotificationAxios.get(`/version`);
    return response.data;
  },
  getVersionBackProxy: async () => {
    const response = await backProxyAxios.get(`/version`);
    return response.data;
  },
  getCarreras: async () => {
    const response = await axios.get(`/dashboard/carrerasList`);
    return response.data;
  },
  getNovedadesPaginated: async (
    limit: number,
    offset: number,
    estadoFilterEnable: boolean,
    filterProcessedWithError: boolean
  ) => {
    const response = await axios.post(`/dashboard/novedades`, {
      limit,
      offset,
      estadoFilterEnable,
      filterProcessedWithError,
    });
    return response.data;
  },
  reprocesarNovedadesConError: async () => {
    const response = await axios.post(`/dashboard/reprocesarNovedadesConError`);
    return response.data;
  },
  descartarNovedad: async (novedad: Novedad) => {
    const response = await axios.post(`/descartarNovedad`, {
      novedad: novedad,
    });
    return response.data;
  },
  procesarNovedad: async (novedad: Novedad) => {
    const response = await axios.post(`/novedad`, {
      novedad: novedad,
      manual: true,
    });
    return response.data;
  },
  getTransaccionEnTransito: async (limit: number, offset: number) => {
    const response = await axios.post(`/dashboard/transaccionesEnTransito`, {
      limit,
      offset,
    });
    return response.data;
  },
  procesarTransaccionesPorLote: async (idList: any[]) => {
    const response = await axios.post(
      `/dashboard/inscribirDesinscribirPorLote`,
      { idList }
    );
    return response.data;
  },
  delTransacciones: async () => {
    const response = await axios.post(`/dashboard/borrarTransacciones`, {});
    return response.data;
  },
  delEventos: async () => {
    const response = await axios.post(`/dashboard/borrarEventos`, {});
    return response.data;
  },
  exportEventos: async () => {
    const response = await axios.post(`/dashboard/exportEventos`, {});
    return response.data;
  },
  getEventosPaginated: async (
    limit: number,
    offset: number,
    tipoEvento: string | null,
    idTx: number | null,
    componente: string | null,
    idCupo: number | null,
    idAlumnoPrograma: number | null
  ) => {
    const response = await axios.post(`/dashboard/eventos`, {
      limit,
      offset,
      tipoEvento,
      idTx,
      componente,
      idCupo,
      idAlumnoPrograma,
    });
    return response.data;
  },
  exportTransacciones: async (fecha: number, hora: number | null) => {
    const response = await axios.post(`/dashboard/exportTransacciones`, {
      fecha,
      hora,
    });
    return response.data;
  },
  getEstadoGlobalTransacciones: async () => {
    const response = await axios.post(`/dashboard/estadoGlobalTx`, {});
    return response.data;
  },
  getTransaccionesPaginated: async (
    limit: number,
    offset: number,
    fecha: number,
    hora: number | null,
    estado: string | null,
    idTx: number | null
  ) => {
    const response = await axios.post(`/dashboard/transacciones`, {
      limit,
      offset,
      fecha,
      hora,
      estado,
      idTx,
    });
    return response.data;
  },
  deleteNovedad: async (idNovedad: number) => {
    const response = await axios.delete(`/dashboard/novedad`, {
      data: { idNovedad },
    });
    return response.data;
  },
  deleteNovedades: async () => {
    const response = await axios.delete(`/dashboard/novedades`);
    return response.data;
  },
  setNovedadPendiente: async (novedadId: number) => {
    const response = await axios.post(`/dashboard/setNovedadPendiente`, {
      novedadId,
    });
    return response.data;
  },
};
