import React, { useEffect, useState } from "react";
import "./Validaciones.css";
import { useAppDispatch } from "../../app/store";
import { useSelector } from "react-redux";
import {
  selectLoaderPage,
  selectValidaciones,
} from "../../features/Dashboard/Slices/dashboardSlice";
import Button from "../../components/reusable/Button/Button";
import Input from "../../components/reusable/Input/Input";
import PageLoader from "../../components/reusable/PageLoader/PageLoader";
import "./Validaciones.css";
import ModalConfirmation from "../../components/reusable/ModalConfirmation/ModalConfirmation";
import { dashboardAPI } from "../../Api/dashboardAPI";
import { toastError, toastSuccess } from "../../utils/toast";
import {
  getInicializarValidaciones,
  getValidaciones,
} from "../../features/Dashboard/AsyncThunks/validaciones/getValidaciones";

function Validaciones() {
  const dispatch = useAppDispatch();
  const loading = useSelector(selectLoaderPage);
  const validaciones = useSelector(selectValidaciones);
  const [validationIdToEdit, setValidationIdToEdit] = useState<null | number>(
    null
  );
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const [activaValue, setActivaValue] = useState("");
  const [configuracionValue, setConfiguracionValue] = useState("");

  const obtenerValidaciones = async () => {
    setValidationIdToEdit(null);
    await dispatch(getValidaciones());
    clearNewValuesSaved();
  };

  const inicializarValidaciones = async () => {
    setValidationIdToEdit(null);
    await dispatch(getInicializarValidaciones());
    clearNewValuesSaved();
  };

  const clearNewValuesSaved = () => {
    setActivaValue("");
    setConfiguracionValue("");
  };

  const prepareUpdateValidacionBody = () => {
    let activa;
    let configuracion;
    const validacionToEdit = validaciones.find(
      (v) => v.id === validationIdToEdit
    );
    if (!validacionToEdit) {
      throw new Error("No se encuentra validacion");
    } else {
      if (configuracionValue.length) {
        if (configuracionValue === "null") {
          configuracion = "";
        } else {
          configuracion = configuracionValue;
        }
      } else {
        configuracion = String(validacionToEdit.configuracion);
      }

      if (activaValue.length) {
        if (activaValue === "null") {
          activa = "";
        } else {
          activa = activaValue;
        }
      } else {
        activa = String(validacionToEdit.activa);
      }
    }

    return {
      activa,
      configuracion,
      id: String(validationIdToEdit || ""),
    };
  };

  const actualizarValidacion = async () => {
    try {
      if (validationIdToEdit) {
        console.log("prepareUpdateValidacionBody :>> ");
        const validacionBody = prepareUpdateValidacionBody();
        console.log("validacionBody :>> ", validacionBody);
        const { activa, configuracion, id } = validacionBody;
        await dashboardAPI.updateValidacion(activa, configuracion, id);
        toastSuccess(
          `Validación con id = ${validationIdToEdit} actualizada con éxito!`
        );
      }
    } catch (error: any) {
      toastError(error?.message || "");
    } finally {
      obtenerValidaciones();
      closeModalConfirmation();
    }
  };

  const closeModalConfirmation = () => setShowModalConfirmation(false);

  useEffect(() => {
    obtenerValidaciones();
  }, []);

  const validacionesKeys = Object.keys(
    validaciones.length ? validaciones[0] : {}
  );

  return (
    <div className="p-6 grid grid-cols-4 gap-6">
      <ModalConfirmation
        show={showModalConfirmation}
        onClose={closeModalConfirmation}
        onConfirm={actualizarValidacion}
        title="Actualizar Validacion"
        message={`Seguro que desea actualizar la validación con id = ${validationIdToEdit} ?`}
      />

      <div className="bg-white p-6 rounded-lg col-span-3 border-2 border-cardBorder overflow-x-hidden">
        <h4 className="font-semibold text-textSecondary mb-3 text-[16px]">
          Validaciones
        </h4>
        <div className="validacionesContainer">
          {!loading &&
            validacionesKeys.map((key) => {
              return (
                <div className="flex flex-col">
                  <div className="box">
                    <strong className="text-center">{key}</strong>
                  </div>
                  {validaciones.map((validacion: any) => {
                    const editMode = validationIdToEdit === validacion.id;
                    if (key === "configuracion") {
                      return (
                        <div className="flex">
                          <div className="box">
                            <Input
                              defaultValue={validacion[key] || ""}
                              onChange={setConfiguracionValue}
                              disabled={!editMode}
                            />
                          </div>
                        </div>
                      );
                    }
                    if (key === "activa") {
                      return (
                        <div className="flex">
                          <div className="box">
                            <Input
                              defaultValue={validacion[key] || ""}
                              onChange={setActivaValue}
                              disabled={!editMode}
                            />
                          </div>
                        </div>
                      );
                    }
                    return (
                      <div className="box">
                        <p>{validacion[key]}</p>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          {/* EDIT BUTTTONS */}
          <div className="flex flex-col">
            <div className="box" />
            {validaciones.map((validacion: any) => {
              const editMode = validationIdToEdit === validacion.id;
              return (
                <div className="box">
                  <Button
                    iconName={editMode ? "check-circle" : "pencil-square"}
                    className="w-36"
                    theme={editMode ? "primary" : "blue"}
                    label={editMode ? "Actualizar" : "Editar"}
                    onClick={() => {
                      if (editMode) {
                        setShowModalConfirmation(true);
                      } else {
                        if (activaValue.length || configuracionValue.length) {
                          // Refresh de defaultValues en caso de que no se confirme edicion
                          obtenerValidaciones();
                        }
                        clearNewValuesSaved();
                        setValidationIdToEdit(validacion.id);
                      }
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="bg-white p-6 rounded-lg col-span-1 border-2 border-cardBorder">
        <h4 className="font-semibold text-textSecondary mb-3 text-[16px]">
          Acciones
        </h4>
        <Button
          className="ml-4"
          label="Refresh"
          iconName="arrow-clockwise"
          onClick={obtenerValidaciones}
        />
        <Button
          className="ml-4"
          label="Inicializar"
          iconName="arrow-down-square"
          onClick={inicializarValidaciones}
          theme="blue"
        />
      </div>
    </div>
  );
}

export default Validaciones;
